import { configureStore } from '@reduxjs/toolkit';
import clientReducer from '../feature/client/client'
import DbaReducer from '../feature/DBA/dba'
import ShareHolderReducer from '../feature/shareholder/shareholder'
import ContactReducer from '../feature/contacts/contacts'
import TaxAccountReducer from '../feature/TaxAccount/taxaccount'
import messageReducer from '../feature/message/message'
import loadingReducer from '../feature/loading/loading'
import ClientPortalUsersReducer from '../feature/ClientPortalUser/users'
import Roles_permissionReducer from '../feature/Roles_Permission/roles'
import folderReducer  from '../feature/Folder/Folder'













export const store = configureStore({
  reducer: {
    client: clientReducer,
    dba:DbaReducer,
    shareholder:ShareHolderReducer,
    contact:ContactReducer,
    TaxAccount:TaxAccountReducer,
    clientPortal:ClientPortalUsersReducer,
    message:messageReducer,
    loading:loadingReducer,
    Roles:Roles_permissionReducer,
    folder: folderReducer
  },
});
