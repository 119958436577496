
// import Header from './Header'
// import Loading from './Loading';
// import PageTitle from './Page_Title';
// import { useSelector } from 'react-redux';

// const Layout = ({ title, subtitle="", toolbar="", children,selectMedia=null }) => {

//     const isLoading = useSelector((state) => state.loading.loading);
   
//     return (

//         <>
//           {!selectMedia && <Header />}
//             {isLoading && <Loading/>}
//             <section id="page-content" className="main-content">
//                 <div className="container-fluid">
//                 { !selectMedia&&<PageTitle title={title} subtitle={subtitle} toolbar={toolbar}></PageTitle>}
//                     {children}
//                 </div>
//             </section>
//         </>

//     )
// }

// export default Layout





import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import Loading from './Loading';
import PageTitle from './Page_Title';
import { Modal, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';

const Layout = ({ title, subtitle = "", toolbar = "", children, selectMedia = null }) => {
  const isLoading = useSelector((state) => state.loading.loading); // Redux loading state

  const [isLocked, setIsLocked] = useState(false);
  const [modalCountdown, setModalCountdown] = useState(120); // 2 minutes countdown
  const lockTimeoutDuration = 2 * 60 * 1000; // 2 minutes in milliseconds
  let activityTimeout;

  const resetTimer = () => {
    // Clear the existing timeout
    clearTimeout(activityTimeout);

    // Reset the inactivity timeout
    activityTimeout = setTimeout(() => {
      setIsLocked(true);
    }, lockTimeoutDuration);
  };

  useEffect(() => {
    // Reset the timer whenever isLoading changes
    if (isLoading) {
      resetTimer();
    }
  }, [isLoading]); // Dependency on isLoading

  useEffect(() => {
    // Reset the timer on any click event in the document
    const handleClickActivity = () => {
      resetTimer();
    };

    document.addEventListener('click', handleClickActivity);

    resetTimer(); // Initial timer setup

    return () => {
      // Cleanup on unmount
      clearTimeout(activityTimeout);
      document.removeEventListener('click', handleClickActivity);
    };
  }, []);

  useEffect(() => {
    if (isLocked) {
      const countdownInterval = setInterval(() => {
        setModalCountdown((prev) => prev - 1);
      }, 1000);

      const logoutTimeout = setTimeout(() => {
        Cookies.remove('admone', { path: '/', domain: process.env.REACT_APP_COOKIE_URL || undefined });
        window.location.href = '/login';
      }, lockTimeoutDuration);

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(logoutTimeout);
      };
    }
  }, [isLocked]);

  const handleReload = () => {
    setIsLocked(false);
    setModalCountdown(120); // Reset the countdown
    resetTimer(); // Restart the activity timer
  };

  const handleLogoutNow = () => {
    Cookies.remove('admone', { path: '/', domain: process.env.REACT_APP_COOKIE_URL || undefined });
    window.location.href = '/login';
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      {!selectMedia && <Header />}
      {isLoading && <Loading />}
      <section id="page-content" className="main-content">
        <div className="container-fluid">
          {!selectMedia && <PageTitle title={title} subtitle={subtitle} toolbar={toolbar}></PageTitle>}
          {children}
        </div>
      </section>

      {/* Lock Screen Modal */}
      <Modal
        show={isLocked}
        onHide={() => {}}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>You’ve Been Idle for a While!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your session will end in <strong>{formatTime(modalCountdown)}</strong> due to inactivity.
          </p>
          <p>Please continue using the app to keep your session active.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleReload}>
            Stay Logged In
          </Button>
          <Button variant="danger" onClick={handleLogoutNow}>
            Log Out Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Layout;














