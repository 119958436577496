import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteChatModal = ({ show, handleClose, handleConfirm ,handleDeleteClick,threadId,setShowDeleteModal}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this chat? This action cannot be undone.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className='text-white' variant="danger" onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the handleRowClick
                  handleDeleteClick(threadId);
                  setShowDeleteModal(false)
                  
                }}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteChatModal;
