// utils/validation.js

export const validateComposeChatFields = ({ selectedClient, selectedUsers, subject, newmessage }) => {
    const errors = {
      clientError: '',
      userError: '',
      subjectError: '',
      messageError: '',
    };
  
    if (!selectedClient) {
      errors.clientError = 'Please select a client.';
    }
    
    if (selectedUsers.length === 0) {
      errors.userError = 'Please select at least one user.';
    }
  
    if (!subject.trim()) {
      errors.subjectError = 'Please enter a subject.';
    }
  
    if (!newmessage.trim()) {
      errors.messageError = 'Please enter a message.';
    }
  
    return errors;
  };
  



  export const validateNewClientPoratlUser = (user) => {
    const errors = {};
  
    if (!user.firstName) {
      errors.firstName = "First name is required.";
    }
    if (!user.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (!user.email || !/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "A valid email address is required.";
    }
  
    if (user.client_ids.length === 0) {
      errors.clients = "At least one client must be selected.";
    }
  
    return errors;
  };






  export const validateFileupload = (files) => {
    const errors = {};
  if (files.length === 0) {
      errors.files = "At least one file must be selected.";
    }
  
 
  
    return errors;
  };


  export const validateFolderSelection = (selectedFolder) => {
    return selectedFolder !== ""; 
  };




  export const validateFolderCreation = (folderName, selectedFiles) => {
    let errors = {};
  
    if (!folderName.trim()) {
      errors.folderName = "Folder name is required.";
    }
  
    // if (selectedFiles.length === 0) {
    //   errors.selectedFiles = "At least one file must be selected.";
    // }
  
    return errors;
  };






export const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
export const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'xlsx', 'docx', 'pptx'];

export const validateFiles = (files) => {
  const errors = [];

  Array.from(files).forEach((file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB

    if (fileSizeMB > MAX_FILE_SIZE_MB) {
      errors.push({
        fileName: file.name,
        reason: `File size exceeds ${MAX_FILE_SIZE_MB} MB`,
      });
    }

    if (!allowedExtensions.includes(fileExtension)) {
      errors.push({
        fileName: file.name,
        reason: 'Invalid file format',
      });
    }
  });

  return errors;
};



export const validateEmail = (email) => {
  if (!email) {
      return 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(email)) {
      return 'Invalid email format';
  }
  return '';
};

export const validatePassword = (password) => {
  if (!password) {
      return 'Password is required';
  } else if (password.length < 6) {
      return 'Password must be at least 6 characters long';
  }
  return '';
};








