import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Select from "react-select";

const ChatHeader = ({
  unreadCount,
  searchKeyword,
  changeSearch,
  handleShow,
  handleSelectChange,
  client_id,
  clientList,
  handleInputChangeclient,
  options,
}) => {
  // const client = useSelector(getAllclients)

  return (
    <div className="chats-header">
      <div className="container-fluid ">
        <div className="chats-header__left unread_count">
          {unreadCount} unread messages
        </div>

        <div className="chats-header__right toolbar">
          <div className="company-filter">
            {/* <Select
     
        options={options}
        onInputChange={handleInputChangeclient}
        onChange={handleSelectChange}
        placeholder="Search and select clients..."
        style={{ fontSize: "0.7rem" }}
      /> */}

            <Select
              options={options}
              onInputChange={handleInputChangeclient}
              onChange={handleSelectChange}
              placeholder="Search and select clients..."
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "0.7rem", // Font size for the control (input area)
                  padding: "0.25rem 0.5rem", // Reduced padding to match form-control-sm
                  minHeight: "31px", // Adjust height to match form-control-sm
                  // height: '30px', // Ensure the height is consistent
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: "0.25rem", // Padding for the dropdown indicator (arrow)
                }),
                input: (provided) => ({
                  ...provided,
                  fontSize: "0.7rem", // Font size for the input text
                  padding: "0px", // Remove extra padding for input text
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: "0.7rem", // Font size for the selected value
                  padding: "0px", // Padding for the selected value
                }),
                menu: (provided) => ({
                  ...provided,
                  padding: "0px", // Optional: Adjust padding for the dropdown menu items
                }),
                option: (provided) => ({
                  ...provided,
                  padding: "0.25rem 0.5rem", // Padding for the individual dropdown options
                  fontSize: "0.7rem", // Match font size with the control
                }),
              }}
            />
          </div>
          <div className="search">
            <InputGroup className="">
              <FormControl
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchKeyword}
                onChange={changeSearch}
                style={{ boxShadow: "none" }}
                className="form-control form-control-md"
              />
            </InputGroup>
          </div>
        </div>
        <div className="new-chat-btn">
          <Button className="btn btn-sm" onClick={handleShow}>
            New Chat
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
