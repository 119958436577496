import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folderPath: [], 
  currentFolderId: null, 
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    setFolderPath: (state, action) => {
      state.folderPath = action.payload;
      state.currentFolderId = action.payload.length > 0 ? action.payload[action.payload.length - 1]?.id : null; // Update currentFolderId based on the last folder in the path
    },
    appendToFolderPath: (state, action) => {
      state.folderPath.push(action.payload); // Add new folder to path
      state.currentFolderId = action.payload.id; // Set current folder ID
    },
    removeFromFolderPath: (state, action) => {
      state.folderPath = state.folderPath.slice(0, action.payload + 1);
      state.currentFolderId =
        state.folderPath[state.folderPath.length - 1]?.id || null;
    },
    resetFolderPath: (state) => {
      state.folderPath = [];
      state.currentFolderId = null; // Explicitly reset currentFolderId
    },
  },
});

export const {
  setFolderPath,
  appendToFolderPath,
  removeFromFolderPath,
  resetFolderPath,
} = folderSlice.actions;

export default folderSlice.reducer;

