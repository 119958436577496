// const message={
//     loading: "Please wait...",
//     dataNotFound: "No data found.",
//     filesNotFound: "No files found for this client",
//     uploading:"Uploading...",
//     fileSaved:"File Saved",
//     fileMoveFolder:"File move to folder",
//     fileDeleted:"File delete",
//     fileRecover:"File recover",
//     folderCreated:"Folder created",
//     folderDeleted:"Folder deleted",
//     fileRemoveFromFolder:"File Removed from folder",
//     userAdded:"New User added"
    

// }


// export default message


const message = {
    loading: "Please wait...",
    dataNotFound: "No data available.",
    filesNotFound: "No files found for this client.",
    uploading: "Uploading...",
    fileSaved: "File saved successfully.",
    fileMoveFolder: "File moved to folder.",
    fileDeleted: "File deleted.",
    fileRecover: "File recovered.",
    folderRecover:"Folder recovered",
    folderCreated: "Folder created successfully.",
    folderDeleted: "Folder deleted.",
    fileRemoveFromFolder: "File removed from folder.",
    userAdded: "New user added successfully."
};

export default message;