import React, { useEffect, useState } from "react";
import {
  foldericon,
  removeIcon,
} from "../../../utils/icons";
import { getFileIcon } from "../../../utils/fileIcon";
import { Modal } from "react-bootstrap";
import FileManagerService from "../../../services/FileManager.service";
import PreviewFileModal from "./Filemanager_PreviewFileModal";
import FolderService from "../../../services/folder.service";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch, useSelector } from "react-redux";
import { handleVisibilityChange } from "../../../utils/visible";
import { downloadFile } from "../../../utils/downloadFiles";
import { appendToFolderPath, removeFromFolderPath, resetFolderPath } from "../../../feature/Folder/Folder";
import BreadcrumbNavigation from "../../../components/Breadcrumbs";

const Files = ({ files, folders, FolderIdEdit, folderDetails, FolderName,setActiveTab }) => {
  const userFolder = localStorage.getItem("Userfolder");
  const folderPath = useSelector((state) => state.folder.folderPath);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [fileVisibilities, setFileVisibilities] = useState({});

  // Sync fileVisibilities with the files prop whenever files change
  useEffect(() => {
    if (files && files.length > 0) {
      const visibilityMap = files.reduce((acc, file) => {
        acc[file.id] = file.visible;
        return acc;
      }, {});
      setFileVisibilities(visibilityMap);
    }
  }, [files]);

  const handleClose = () => setShow(false);

  const downloadExtensions = ["docx", "xlsx", "pptx"];

  const handleShow = async (fileId, fileUrl, fileName, fileExtension) => {
    try {
      const res = await FileManagerService.filePreview(fileId);

      if (res) {
        const blob = res.data;
        const blobUrl = URL.createObjectURL(blob);
        setFileBlob(blobUrl);
        setFileType(res.headers["content-type"]);
        if (downloadExtensions.includes(fileExtension) && blobUrl) {
          downloadFile(blobUrl, fileName);
        } else {
          setShow(true);
        }
      } else {
        console.error("Failed to fetch file");
      }
    } catch (err) {
      setFileBlob(null);
      setShow(true);
      console.log(err);
    }
  };

  const deleteFileToFolder = async (file_id) => {
    const data = {
      file_id: file_id,
    };
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.uploading));
      await FolderService.DeleteFileToFolder(FolderIdEdit, data);
      folderDetails(FolderIdEdit, FolderName);

      dispatch(setMessage(message.fileRemoveFromFolder));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  const handleImmediateVisibilityChange = async (fileId, currentVisibility) => {
    setFileVisibilities((prevVisibilities) => ({
      ...prevVisibilities,
      [fileId]: !currentVisibility,
    }));

    await handleVisibilityChange(fileId, currentVisibility);
  };

  const handleFolderClick = (id, name) => {
    // console.log("folderId",folderId)
    // console.log("folderName",folderName)

     dispatch(appendToFolderPath({ id, name })); // Update Redux path
    folderDetails(id, name); // Update parent folder details
  };


    const handleBreadcrumbClick = (index) => {
      dispatch(removeFromFolderPath(index)); // Remove from path
      const targetFolder = folderPath[index];
      if (targetFolder) {
        folderDetails(targetFolder.id, targetFolder.name);
      }
    };
  
    const handleResetPath = () => {
      dispatch(resetFolderPath()); // Reset path to root
      setActiveTab("Files&Folder")
    };

  return (
    <>
  {/* <BreadcrumbNavigation
        folderPath={folderPath}
        handleResetPath={handleResetPath}
        handleBreadcrumbClick={handleBreadcrumbClick}
      /> */}
   
    <div className="list-table table-responsive">
      <table className="table table-hover table-sm mt-4">
        <thead>
          <tr>
            <th width="40%">Name</th>
            <th width="15%">Uploaded By</th>
            <th width="15%">Uploaded on</th>
            <th width="10%">Status</th>
            <th width="10%" className="text-center">
              Visible to Client
            </th>
            <th width="10%" className="text-center">
              Remove
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Render Folders */}
          {folders.length > 0 &&
            folders.map((folder) => (
              <tr key={`folder-${folder.id}`}>
                <td>
                  <div
                    className="d-flex gap-1 align-items-center"
                    role="button"
                    onClick={() => handleFolderClick(folder.id, folder.name)}
                  >
                    <img
                      src={foldericon}
                      alt="Folder Icon"
                      className="file-icon"
                    />
                    <span>{folder.name}</span>
                  </div>
                </td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td className="text-center">--</td>
                <td align="center">--</td>
              </tr>
            ))}

          {/* Render Files */}
          {files.length > 0 ? (
            files.map((file, index) => (
              <tr key={`file-${index}`}>
                <td>
                  <div className="d-flex gap-1 align-items-center">
                    <div role="button">
                      <img
                        src={getFileIcon(file.file_name)}
                        alt={`${file.file_name} Icon`}
                        className="file-icon"
                      />
                    </div>
                    <span
                      role="button"
                      onClick={() =>
                        handleShow(
                          file.id,
                          file.file_url,
                          file.file_name,
                          file.file_type
                        )
                      }
                    >
                      {file.file_name}
                    </span>
                  </div>
                </td>
                <td>{file.uploaded_by}</td>
                <td>{file.uploaded_on}</td>
                <td>
                  <span
                    className={`badge rounded-pill ${
                      file.status.toLowerCase() === "approved"
                        ? "bg-success"
                        : "bg-secondary"
                    }`}
                  >
                    {file.status.toUpperCase()}
                  </span>
                </td>
                <td className="text-center">
                  <div className="form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={fileVisibilities[file.id] == true}
                      onChange={() =>
                        handleImmediateVisibilityChange(
                          file.id,
                          fileVisibilities[file.id]
                        )
                      }
                    />
                  </div>
                </td>
                <td align="center">
                  <img
                    role="button"
                    style={{ width: "20px" }}
                    title="Delete this file"
                    src={removeIcon}
                    alt="trash icon"
                    onClick={() => deleteFileToFolder(file.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No files or folders found</td>
            </tr>
          )}
        </tbody>
      </table>

      <PreviewFileModal
        show={show}
        handleClose={handleClose}
        fileUrl={fileBlob}
        fileType={fileType}
      />
    </div>
    </>
  );
};

export default Files;
