import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import FileManagerService from "../services/FileManager.service";
import Pagination from "../components/Pagination";
import { getFileIcon } from "../utils/fileIcon";

const MediaManager = ({ onSelect, client_id, title = "Attach Files" }) => {
  const [show, setShow] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const baseUrl = process.env.REACT_APP_MEDIA_URL;

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentPage(1);
    setCurrentSet(1);
    setSearchQuery("");
    setShow(true);
  };
  useEffect(() => {
    if (show) {
      fetchExistingFiles(currentPage, searchQuery);
    }
  }, [show, currentPage, searchQuery]);

  const fetchExistingFiles = async (page, query) => {
    const data = {
      search: query,
      clientId: client_id,
      page,
      limit: 25,
    };
    try {
      const response = await FileManagerService.getFile(data);
      setExistingFiles(response.files || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error("Error fetching existing files:", error);
    }
  };

  const saveFile = async (fileDetails) => {
    const data = {
      clientId: client_id,
      files: fileDetails,
    };

    try {
      await FileManagerService.saveFile(data);
    } catch (error) {
      console.error("Error saving file:", error);
      setFileError("File save failed. Please try again.");
    }
  };

  // const handleFileUpload = async (event) => {
  //   const files = Array.from(event.target.files);
  //   const formData = new FormData();

  //   files.forEach((file) => formData.append("file", file));
  //   formData.append("clientId", client_id);

  //   try {
  //     setIsUploading(true);
  //     const response = await FileManagerService.uploadFile(formData);
  //     console.log("response",response)

  //     // const fileDetailsArray = response.map((file) => ({
  //     //   originalFileName: file.originalFileName,
  //     //   uniqueFileName: file.uniqueFileName,
  //     //   visibility: 1,
  //     // }));

  //     const fileDetailsArray = [
  //       {
  //         originalFileName: response.originalFileName,
  //         uniqueFileName: response.uniqueFileName,
  //         visibility: 1,
  //       },
  //     ];
  //     await saveFile(fileDetailsArray);

  //     fetchExistingFiles(currentPage, searchQuery);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     setFileError("File upload failed. Please try again.");
  //   } finally {
  //     setIsUploading(false);
  //   }
  // };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files); // Convert files to array
    setIsUploading(true); // Set uploading flag
    setFileError(""); // Reset errors
    setUploadMessage("Uploading..."); // Show uploading message

    try {
      // Process files one by one
      for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("clientId", client_id);

        try {
          const response = await FileManagerService.uploadFile(formData);
          console.log("response", response);

          // Assuming response contains single file details
          const fileDetails = [
            {
              originalFileName: response.originalFileName,
              uniqueFileName: response.uniqueFileName,
              visibility: 1,
            },
          ];

          await saveFile(fileDetails); // Save uploaded file info
          fetchExistingFiles(currentPage, searchQuery); // Refresh file list
        } catch (uploadError) {
          console.error(`Error uploading file ${file.name}:`, uploadError);
          setFileError(`Failed to upload ${file.name}. Please try again.`);
        }
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      setFileError("An error occurred while uploading files.");
    } finally {
      setIsUploading(false); // Reset uploading flag
      setUploadMessage(""); // Hide uploading message
    }
  };

  const handleCheckboxChange = (file) => {
    setSelectedFiles((prev) =>
      prev.find((f) => f.id === file.id)
        ? prev.filter((f) => f.id !== file.id)
        : [...prev, file]
    );
  };

  const handleConfirm = () => {
    const selectedFilesInfo = selectedFiles.map((file) => ({
      id: file.id,
      file_name: file.file_name,
    }));
    onSelect(selectedFilesInfo);
    setSelectedFiles([]);
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Attach Files
      </Button> */}
      <button
        className="btn btn-sm btn-outline-primary ms-2 "
        onClick={(event) => handleShow(event)}
      >
        {/* Attach Files */}
        {title}
      </button>
      <Modal show={show} onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-1 ">
          <Form>
            <Form.Group controlId="uploadFiles">
              <div className="d-flex  align-items-center justify-content-between py-2 ">
                <div className="w-50 d-flex">
                  <Form.Label
                    className="me-1 text-primary"
                    style={{ width: "200px" }}
                  >
                    Upload New Files
                  </Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    className="form-control form-control-sm "
                    disabled={isUploading}
                  />

                  {uploadMessage && (
                    <span
                      className="ms-2 text-primary"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {uploadMessage}
                    </span>
                  )}
                </div>
                {/* <div className="text-end d-flex justify-content-end"> */}
                <Form.Group controlId="searchFiles" className=" w-25">
                  <Form.Control
                    type="text"
                    placeholder="Search by filename"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className=" align-self-end  form-control form-control-sm "
                  />
                </Form.Group>
              </div>
              {/* </div> */}

              <div>
                {uploadMessage && (
                  <span
                    className="ms-2 text-primary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {uploadMessage}
                  </span>
                )}
              </div>

              {fileError && <p className="text-danger">{fileError}</p>}
            </Form.Group>
          </Form>
          <div className="table-responsive mt-3 media-manager-popup">
            <div className="table-wrapper">
              <table className="table table-sm table-bordered table-hover">
                <thead>
                  <tr>
                    <th width="2%" className="invisible">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setSelectedFiles(
                            e.target.checked ? [...existingFiles] : []
                          )
                        }
                        checked={
                          existingFiles.length > 0 &&
                          selectedFiles.length === existingFiles.length
                        }
                      />
                    </th>
                    <th width="35%">Name</th>
                    <th width="23%">Uploaded By</th>
                    <th width="25%">Uploaded On</th>
                  </tr>
                </thead>
                <tbody>
                  {existingFiles.length > 0 ? (
                    existingFiles.map((file) => (
                      <tr key={file.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedFiles.find(
                              (f) => f.id === file.id
                            )}
                            onChange={() => handleCheckboxChange(file)}
                          />
                        </td>
                        <td>
                          <div className="file-name">
                            <div className="file-icon">
                              <img
                                src={getFileIcon(file.file_name)}
                                alt="icon"
                                style={{
                                  width: "15px",
                                  marginRight: "10px",
                                  flexShrink: 0,
                                }}
                              />
                            </div>
                            <div className="file-name-text">
                              {file.file_name}
                            </div>
                          </div>
                        </td>
                        <td>{file.uploaded_by}</td>
                        <td>{file.uploaded_on}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No files found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Pagination
              currentPage={currentPage}
              TotalPage={totalPages}
              currentSet={currentSet}
              setCurrentSet={setCurrentSet}
              setcurrentPage={setCurrentPage}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={selectedFiles.length === 0}
          >
            Confirm Selection
          </Button> */}
          <button
            className="btn btn-sm btn-outline-primary "
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={handleConfirm}
            disabled={selectedFiles.length === 0}
          >
            Confirm Selection
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MediaManager;
