import React, { useState } from "react";
import PreviewFileModal from "./Filemanager_PreviewFileModal";
import { getFileIcon } from "../../../utils/fileIcon";
import { folders, trashicon } from "../../../utils/icons";
import { formatDateTime } from "../../../utils/dateTimeFormat";
import { downloadFile } from "../../../utils/downloadFiles";
import Pagination from "../../../components/Pagination";
import FileManagerService from "../../../services/FileManager.service";
import DeleteConfirmationModal from "./Filemanager_DeleteModal";

const FilesFolder = ({
  responseData,
  currentPage,
  totalPages,
  setCurrentPage,
  onFileSelect,
  selectedFiles = [],
  selectMedia = false,
  handleFolderClick,
  handleDeleteFolder,
  handleDeleteFile,
  folderDetails,
  DeleteFolder,
  trashedFile,
}) => {
  const [show, setShow] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [folderId, setfolderId] = useState("");
  const downloadExtensions = ["docx", "xlsx", "pptx"];
  const handleClose = () => setShow(false);
  const handleDeleteClose = () => setShowDeleteModal(false);

  // const handleFilePreview = async (fileId, fileType, fileName) => {
  //   try {
  //     const blob = await downloadFile(fileId); // Adjust your API call for preview
  //     const blobUrl = URL.createObjectURL(blob);
  //     setFileBlob(blobUrl);
  //     setFileType(fileType);
  //     setShow(true);
  //   } catch (err) {
  //     console.error("Error previewing file:", err);
  //   }
  // };

  const handleShow = async (fileId, fileUrl, fileName, fileExtension) => {
    try {
      const res = await FileManagerService.filePreview(fileId);
      // console.log(res)
      if (res) {
        const blob = res.data;
        const blobUrl = URL.createObjectURL(blob);
        setFileBlob(blobUrl);
        setFileType(res.headers["content-type"]);
        if (downloadExtensions.includes(fileExtension) && blobUrl) {
          downloadFile(blobUrl, fileName);
        } else {
          setShow(true);
        }
      } else {
        console.error("Failed to fetch file");
      }
    } catch (err) {
      setFileBlob(null);
      setShow(true);
      console.log(err);
    }
  };

  const handleDeleteClick = (id) => {
    setfolderId(id);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="list-table table-responsive table-wrapper  ">
        <table className="table table-hover table-borders table-sm mt-4">
          <thead>
            <tr>
              {selectMedia && (
                <th width="5%" className="invisible">
                  Select
                </th>
              )}
              <th width="40%">Name</th>
              <th width="10%">Type</th>
              <th width="15%">Uploaded By</th>
              <th width="15%">Uploaded On</th>
              <th width="10%">Status</th>
              {!selectMedia && (
                <th width="10%" className="text-center">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {/* Render Subfolders */}
            {responseData.subfolders?.data?.map((folder) => (
              <tr key={`folder-${folder.id}`}>
                {selectMedia && <td></td>}
                <td>
                  <div className="file-img_name">
                    <img
                      src={folders}
                      alt="Folder Icon"
                      style={{ width: "20px" }}
                    />
                    <span
                      role="button"
                      className="ms-2 file-name"
                      onClick={() => folderDetails(folder.id, folder.name)}
                    >
                      {/* {folder.name} */}
                      {folder.name}{" "}
                      {folder.IsTrash && (
                        <span style={{ color: "red" }}>(From Trash)</span>
                      )}
                    </span>
                  </div>
                </td>
                <td>Folder</td>
                <td>{folder.created_by}</td>
                <td>{folder.uploaded_on}</td>
                <td>-</td>
                {!selectMedia && (
                  <td className="text-center">
                    <img
                      role="button"
                      style={{ width: "18px" }}
                      title="Delete this folder"
                      src={trashicon}
                      alt="Delete Icon"
                      // onClick={() =>DeleteFolder(folder.id)}
                      onClick={() => handleDeleteClick(folder.id)}
                    />
                  </td>
                )}
              </tr>
            ))}

            {/* Render Files */}
            {responseData.files?.data?.map((file) => (
              <tr key={`file-${file.id}`}>
                {selectMedia && (
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedFiles.some(
                        (selectedFile) => selectedFile.id === file.id
                      )}
                      onChange={() => onFileSelect(file)}
                    />
                  </td>
                )}
                <td>
                  <div className="d-flex align-items-center file-img_name">
                    <img
                      src={getFileIcon(file.file_name)}
                      alt="File Icon"
                      style={{ width: "20px" }}
                    />
                    <span
                      role="button"
                      className="ms-2 file-name"
                      onClick={() =>
                        handleShow(
                          file.id,
                          file.file_url,
                          file.file_name,
                          file.file_type
                        )
                      }
                    >
                      {file.file_name}
                    </span>
                  </div>
                </td>
                <td>File</td>
                <td>{file.uploaded_by}</td>
                <td>{file.uploaded_on}</td>
                <td>
                  <span
                    className={`badge rounded-pill ${
                      file.status.toLowerCase() === "approved"
                        ? "bg-success"
                        : "bg-secondary"
                    }`}
                  >
                    {file.status.toUpperCase()}
                  </span>
                </td>
                {!selectMedia && (
                  <td className="text-center">
                    <img
                      role="button"
                      style={{ width: "18px" }}
                      title="Delete this file"
                      src={trashicon}
                      alt="Delete Icon"
                      onClick={() => handleDeleteFile(file.id)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          TotalPage={totalPages}
          setcurrentPage={setCurrentPage}
        />
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        show={showDeleteModal}
        folderClose={handleDeleteClose}
        folderId={folderId}
        DeleteFolder={DeleteFolder}
        trashedFile={trashedFile}
      />

      {/* File Preview Modal */}
      {show && (
        <PreviewFileModal
          show={show}
          handleClose={handleClose}
          fileUrl={fileBlob}
          fileType={fileType}
        />
      )}
    </>
  );
};

export default FilesFolder;
