import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Filemanager from "../pages/filemanager/Filemanager";

const MediaManager2 = ({ client_id, onSelect }) => {
  const [show, setShow] = useState(false); // State to control modal visibility
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Function to handle file selection
  const handleFileSelect = (files) => {
    
    setSelectedFiles(files);
  };

  // Function to confirm selected files
  const handleConfirm = () => {
    onSelect(selectedFiles); // Pass selected files to the parent
    setShow(false); // Close the modal
  };

  const handleShow=(event )=>{
    event.stopPropagation();
    event.preventDefault();
    setShow(true)
  }
  return (
    <div>
      {/* Attach Files Button */}
      <button
        className="btn btn-sm btn-primary"
        onClick={(event) =>handleShow(event)}
      >
        Attach Files
      </button>

      {/* MediaManager2 Modal */}
      <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose file to attach</Modal.Title>
        </Modal.Header>
        <Modal.Body className="media-manager-popup">
          {/* Filemanager component embedded */}
          <Filemanager
            client_id={client_id}
            onFileSelect={handleFileSelect} // Callback to capture selected files
            selectMedia={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={selectedFiles.length === 0}
          >
            Confirm Selection
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MediaManager2;
