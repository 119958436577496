import React, { useEffect, useState } from "react";
import Files from "./components/Filemanager_Files";
import Layout from "../../components/layout/Layout";
import Folders from "./components/Filemanager_Folders";
import Trashed from "./components/Filemanager_TrashedFiles";
import FolderDeatils from "./components/Filemanager_folderDetails";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Filemanger_uploadfiles from "./components/Filemanger_uploadfiles";
import Filemanager_createfolder from "./components/Filemanager_createfolder";
import Filemanager_editfolder from "./components/Filemanager_editfolder";
// import io from "socket.io-client";
import FileManagerService from "../../services/FileManager.service";
import FolderService from "../../services/folder.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserIdFromToken } from "../../utils/decodeToken";
import { useDispatch, useSelector } from "react-redux";
import message from "../../utils/message";
import clientService from "../../services/client.service";
import AddFiles from "./components/Filemanager_addfile";
import { setMessage } from "../../feature/message/message";
import { setLoading } from "../../feature/loading/loading";
import FilesFolder from "./components/Files&Folder";
import {
  appendToFolderPath,
  removeFromFolderPath,
  resetFolderPath,
  setFolderPath,
} from "../../feature/Folder/Folder";
import BreadcrumbNavigation from "../../components/Breadcrumbs";
// const socket = io(process.env.REACT_APP_API_URL);

const inputStyle = {
  borderColor: "#1E468E",
  position: "relative",
  width: "100%",
};

const Filemanager = ({ client_id, onFileSelect, selectMedia }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const folderPath = useSelector((state) => state.folder.folderPath);
  // console.log("folderPath",folderPath)

  const currentFolderId = useSelector((state) => state.folder.currentFolderId);
  // console.log("currentFolderId",currentFolderId)
  const [show, setShow] = useState(false);
  const [showFolderUploads, setshowFolderUploads] = useState(false);
  const [showTrashed, setShowTrashed] = useState(false); // Tracks whether trashed files are displayed
  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState([]);

  const [trashedFiles, setTrashedFiles] = useState([]);

  const [sortBy, setSortBy] = useState("created_at"); // Default sorting column
  const [sortDirection, setSortDirection] = useState("desc");
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowFolderUplaod = () => setshowFolderUploads(true);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [clientName, setclientName] = useState("");
  // const [currentFolderId, setCurrentFolderId] = useState(null);

  //pagination for trashed
  const [currentPageTrashed, setcurrentPageTrashed] = useState(1);
  const [currentSetTrashed, setCurrentSetTrashed] = useState(1);
  const [TotalPageTrashed, setTotalPageTrashed] = useState("");
  const handleCloseFolderUplaod = () => setshowFolderUploads(false);

  // Fetch files and folders
  const getFilesAndFolders = async (folderId = null) => {
    const data = {
      clientId: id || client_id,
      page: currentPage,
      limit: 25,
      sortBy,
      folderId,
      sortDirection,
    };
    try {
      const response = await FileManagerService.getFile(data);
      setFiles(response);

      dispatch(setFolderPath(response.folderPath));

      // Update the current folder ID and persist it in the URL

      if (folderId) {
        navigate(`?folderId=${folderId}`);
      } else {
        navigate(location.pathname); // Reset the URL if no folderId
      }
    } catch (err) {
      console.error("Error fetching files and folders:", err);
    }
  };

  // Fetch trashed files
  // const trashedFile = async (folderId = null, search = "") => {
  //   const data = {
  //     clientId: id,
  //     search: search,
  //     page: currentPageTrashed,
  //     limit: 25,
  //     folderId,
  //     sortBy: sortBy,
  //     sortDirection: sortDirection,
  //   };
  //   try {
  //     const files = await FileManagerService.TrashedFile(data);

  //     setcurrentPageTrashed(files.currentPage);
  //     setTotalPageTrashed(files.totalPages);
      
  //     if(showTrashed){

  //       dispatch(setFolderPath(files.folderPath));
  //     }
  //     setTrashedFiles(files);
  //   } catch (err) {
  //     setTrashedFiles([]);
  //     console.log(err);
  //   }
  // };

  const trashedFile = async (folderId = null, search = "") => {
    const data = {
      clientId: id,
      search,
      page: currentPageTrashed,
      limit: 25,
      folderId,
      sortBy,
      sortDirection,
    };
  
    try {
      const files = await FileManagerService.TrashedFile(data);
  
      setcurrentPageTrashed(files.currentPage);
      setTotalPageTrashed(files.totalPages);
  
      const params = new URLSearchParams(location.search);
      params.set("trashed", "true");
  
      if (folderId) {
        // Update the URL with folderId in trashed mode
        params.set("folderId", folderId);
      } else {
        // Remove only the folderId parameter
        params.delete("folderId");
      }
  
      window.history.pushState({}, "", `${location.pathname}?${params.toString()}`);
  
      dispatch(setFolderPath(files.folderPath));
      setTrashedFiles(files);
    } catch (err) {
      setTrashedFiles([]);
      console.error("Error fetching trashed files:", err);
    }
  };
  

  

  const folderDetails = async (id, name) => {
    dispatch(appendToFolderPath({ id, name }));
    if (showTrashed) {
      trashedFile(id);
    } else {
      getFilesAndFolders(id);
    }
  };



  const getClientName = async () => {
    try {
      const response = await clientService.getClientById(id);
      console.log("response", response);
      setclientName(response.name);
    } catch (err) {
      console.log(err);
    }
  };




  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const folderId = params.get("folderId");
  //   const isTrashed = params.get("trashed") === "true"; // Check if the trashed query param is true

  //      setShowTrashed(isTrashed);

    
  //   if (folderId) {
  //     // Fetch data for the specific folder
  //     getFilesAndFolders(Number(folderId));
  //   }
  //   else if(isTrashed || showTrashed){
  //     trashedFile();
  //   }
  //   else {
  //     // Fetch data for the root folder
  //     getFilesAndFolders();
      
  //   }


  //   getClientName();
  // }, [location.search, currentPage]);



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const folderId = params.get("folderId");
    const isTrashed = params.get("trashed") === "true";
  
    setShowTrashed(isTrashed);
  
    if (isTrashed) {
      trashedFile(folderId ? Number(folderId) : null);
    } else if (folderId) {
      getFilesAndFolders(Number(folderId));
    } else {
      getFilesAndFolders();
    }
  
    getClientName();
  }, [location.search, currentPage]);



  // const handleShowTrashed = () => {
  //   setShowTrashed(true);
  
  //   // Manually update the URL using window.history
  //   const params = new URLSearchParams(location.search);
  //   params.set("trashed", "true");
  
  //   window.history.pushState({}, "", `${location.pathname}?${params.toString()}`);
  //   trashedFile();
  // };



  // const handleBackToFiles = () => {
  //   setShowTrashed(false);
  //   navigate(location.pathname); 
  //   getFilesAndFolders();
  // };

  const handleShowTrashed = () => {
    setShowTrashed(true);
    const params = new URLSearchParams(location.search);
    params.set("trashed", "true");
    window.history.pushState({}, "", `${location.pathname}?${params.toString()}`);
    trashedFile();
  };
  
  const handleBackToFiles = () => {
    setShowTrashed(false);
    const params = new URLSearchParams(location.search);
    params.delete("trashed");
    params.delete("folderId"); // Clear folderId if present
    window.history.pushState({}, "", `${location.pathname}?${params.toString()}`);
    getFilesAndFolders();
  };

  // const handleBreadcrumbClick = (index) => {
  //   const targetFolder = folderPath[index];
  //   if (targetFolder && targetFolder.id !== 0 && !showTrashed) {
  //     getFilesAndFolders(targetFolder.id || null);
  //   } else if (showTrashed) {
  //     trashedFile(targetFolder.id);
  //   } else {
  //     getFilesAndFolders();
  //   }
  // };

 
  const handleBreadcrumbClick = (index) => {
    const targetFolder = folderPath[index];
    if (targetFolder && targetFolder.id !== 0) {
      if (showTrashed) {
        trashedFile(targetFolder.id);
      } else {
        getFilesAndFolders(targetFolder.id || null);
      }
    } else {
      if (showTrashed) {
        trashedFile();
      } else {
        getFilesAndFolders();
      }
    }
  };


  const handleResetPath = async () => {
    dispatch(resetFolderPath());
    setShowTrashed(false);
    if (showTrashed) {
      trashedFile();
      setShowTrashed(true);
    } else {
      getFilesAndFolders(null);
    }
  };

  // const handleChangeSearch = async (e) => {
  //   const keyword = e.target.value;
  //   setsearchKeyword(keyword);

  //   if (!showTrashed) {
  //     try {
  //       if (keyword.trim() === "") {
  //         if(currentFolderId){
  //         getFilesAndFolders(currentFolderId);
  //         }
  //         else{
  //           getFilesAndFolders()
  //         }
  //       }

         

  //       const response = await FileManagerService.FilterFilesFolder({
  //         searchKeyword: keyword,
  //         folderId:currentFolderId
  //       });

  //       if (response) {
  //         setFiles(response);

  //         const resolveFolderPath = (path) => {
  //           // For folders, exclude the last entry (current folder itself)
  //           return path.slice(0, -1);
  //         };

         
  //         const firstFilePath = response.files.data?.[0]?.path || [];
  //         const firstFolderPath =
  //           response.subfolders.data?.[0]?.path?.length > 0
  //             ? resolveFolderPath(response.subfolders.data[0].path)
  //             : [];

  //         // Update Redux folder path
  //         dispatch(
  //           setFolderPath(
  //             firstFilePath.length > 0 ? firstFilePath : firstFolderPath
  //           )
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error during search:", error);
  //       // setfiles([]);
  //       // setfolderParent([]);
  //       dispatch(setFolderPath([{ id: 0, name: "Home" }])); // Reset path on error
  //     }
  //   } else {
  //     trashedFile(null, keyword);
  //   }
  // };


  const handleChangeSearch = async (e) => {
    const keyword = e.target.value;
    setsearchKeyword(keyword);
  
    if (!showTrashed) {
      try {
        if (keyword.trim() === "") {
          if (currentFolderId) {
            getFilesAndFolders(currentFolderId);
          } else {
            getFilesAndFolders();
          }
          return; // Exit early to avoid additional processing
        }
  
        const response = await FileManagerService.FilterFilesFolder({
          searchKeyword: keyword,
          folderId: currentFolderId,
        });
  
        if (response) {
          setFiles(response);
  
          const resolveFolderPath = (path) => {
            // For folders, exclude the last entry (current folder itself)
            return path && path.length > 1 ? path.slice(0, -1) : path;
          };
  
          // Extract paths from the response
          const firstFilePath = response.files.data?.[0]?.path || [];
          const firstFolderPath =
            response.subfolders.data?.[0]?.path?.length > 0
              ? resolveFolderPath(response.subfolders.data[0].path)
              : firstFilePath; // Use file path if no folders found
  
          // Update Redux folder path
          if (firstFilePath.length > 0 || firstFolderPath.length > 0) {
            dispatch(setFolderPath(firstFilePath.length > 0 ? firstFilePath : firstFolderPath));
          } else {
            // If no files or folders match, retain the current folder path
            dispatch(setFolderPath(currentFolderId ? folderPath : [{ id: 0, name: "Home" }]));
          }
        } else {
          // If the response is null or undefined, retain the current path
          dispatch(setFolderPath(currentFolderId ? folderPath : [{ id: 0, name: "Home" }]));
        }
      } catch (error) {
        console.error("Error during search:", error);
        // Retain the current path on error
        dispatch(setFolderPath(currentFolderId ? folderPath : [{ id: 0, name: "Home" }]));
      }
    } else {
      if (keyword.trim() === "") {
        if (currentFolderId) {
          trashedFile(currentFolderId,keyword);
        } 
        return;
      }

      trashedFile(currentFolderId, keyword);
    }
  };
  
const [selectedFiles, setSelectedFiles] = useState([]);



  const handleFileSelection = (file) => {
    setSelectedFiles((prevSelectedFiles) => {
      const isSelected = prevSelectedFiles.some(
        (selectedFile) => selectedFile.id === file.id
      );
      const updatedFiles = isSelected
        ? prevSelectedFiles.filter(
            (selectedFile) => selectedFile.id !== file.id
          ) 
        : [...prevSelectedFiles, file]; 

      onFileSelect(updatedFiles); 
      return updatedFiles; 
    });
  };

  const handleDelete = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));
      await FileManagerService.DeleteFile(id);
      if (currentFolderId) {
        getFilesAndFolders(currentFolderId);
      } else {
        getFilesAndFolders();
        trashedFile();
      }
      // trashedFile();
      dispatch(setMessage(message.fileDeleted));
    } catch (err) {
      console.log(err);

      dispatch(setMessage(err.response.data.error));
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };


  const DeleteFolder = async (folder_id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.uploading));

      await FolderService.DeleteFolder(folder_id);
      dispatch(setMessage(message.folderDeleted));
      getFilesAndFolders();
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };



  return (
    <section className="filemanager-wrapper">
      <div className="file-manager">
        <Layout
          title={clientName}
          subtitle="FILES MANAGER"
          selectMedia={selectMedia}
        >
          <div className="card">
            <div className="card-body">
              

              <div className="d-flex justify-content-between align-items-center mb-3">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    id="search_clients"
                    className="form-control border-secondary"
                    placeholder="Search files"
                    aria-label="Search"
                    style={{ width: "300px" }}
                    value={searchKeyword}
                    onChange={(e) => handleChangeSearch(e)}
                  />
                  {searchKeyword.trim() && (
                    <button
                      className="btn btn-link text-primary"
                      onClick={() => {
                        setsearchKeyword("");
                        if (showTrashed) {
                          trashedFile(currentFolderId);
                          
                        } else if(currentFolderId){
                          getFilesAndFolders(currentFolderId);
                        }else{
                          getFilesAndFolders()
                        }
                      }}
                    >
                      Clear Search
                    </button>
                  )}
                </div>

                {!selectMedia && (
                  <div className="d-flex justify-content-end align-items-start  gap-2">
  <button
    className="btn btn-outline-primary"
    onClick={() => setshowFolderUploads(true)}
  >
    Create Folder
  </button>
  <button
    className="btn btn-primary"
    onClick={() => setShow(true)}
  >
    Upload File
  </button>

</div>

                )}
              </div>

             

              <div className="mt-3 d-flex align-items-start justify-content-between ">
                <BreadcrumbNavigation
                  folderPath={folderPath}
                  handleBreadcrumbClick={handleBreadcrumbClick}
                  handleResetPath={handleResetPath}
                />
                 <div className="trash-and-back-files text-end">
              <button
    type="button"
    className="btn btn-link text-danger"
    onClick={() => {
      if (showTrashed) {
        handleBackToFiles();
      } else {
        handleShowTrashed();
      }
    }}
  >
    {showTrashed ? "Back to Files" : "Trashed Files"}
  </button>
              </div>
              </div>

              <div className="file-manager__list">
                {showTrashed && trashedFiles.currentPage ? (
                  <Trashed
                    TrashedFile={trashedFiles}
                    trashedFile={trashedFile}
                    getFiles={getFilesAndFolders}
                    setTrashedFiles={setTrashedFiles}
                    folderDetails={folderDetails}
                  />
                ) : (
                  <>
                    {(files.files && files.files.data.length > 0) ||
                    (files.subfolders && files.subfolders.data.length > 0) ? (
                      <FilesFolder
                        responseData={files}
                        getFiles={getFilesAndFolders}
                        folderDetails={folderDetails}
                        handleDeleteFile={handleDelete}
                        onFileSelect={handleFileSelection}
                        selectMedia={selectMedia}
                        selectedFiles={selectedFiles}
                        DeleteFolder={DeleteFolder}
                        trashedFile={trashedFile}
                      />
                    ) : (
                      <div className="text-center mt-4">
                        <h5>No files or folders found</h5>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* Footer with Trashed Files button on the right */}
            {/* {!selectMedia && (
              <div className="card-footer text-end">
              <button
    type="button"
    className="btn btn-link text-danger"
    onClick={() => {
      if (showTrashed) {
        handleBackToFiles();
      } else {
        handleShowTrashed();
      }
    }}
  >
    {showTrashed ? "Back to Files" : "Trashed Files"}
  </button>
              </div>
            )} */}
          </div>
        </Layout>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {`Upload File(s) in ${
              folderPath.length > 0
                ? `Home / ${folderPath
                    .map((folder) => folder.name)
                    .join(" / ")}`
                : "Home"
            }`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filemanger_uploadfiles
            setShow={setShow}
            handleClose={handleClose}
            getFiles={getFilesAndFolders}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showFolderUploads}
        onHide={handleCloseFolderUplaod}
        size="lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Create Folder</Modal.Title> */}
          <Modal.Title>
            {`Create Folder in ${
              folderPath.length > 0
                ? ` Home / ${folderPath
                    .map((folder) => folder.name)
                    .join(" / ")}`
                : "Home"
            }`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filemanager_createfolder
            files={files}
            handleCloseFolderUplaod={handleCloseFolderUplaod}
            getFiles={getFilesAndFolders}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Filemanager;
