import React, { useEffect, useState } from "react";
import { ProgressBar, Button } from "react-bootstrap"; // Import Bootstrap components
import { foldericon } from "../../../utils/icons";
import FolderService from "../../../services/folder.service";
import { useParams } from "react-router-dom";
import { validateFolderCreation } from "../../../utils/validation";
import Error from "../../../components/Error";
import { useDispatch, useSelector } from "react-redux";
import message from "../../../utils/message";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import FileManagerService from "../../../services/FileManager.service";
import Pagination from "../../../components/Pagination";
import { getFileIcon } from "../../../utils/fileIcon";

const FilemanagerCreateFolder = ({ handleCloseFolderUplaod,setfolderParent,getFiles }) => {
 const currentFolderId = useSelector((state) => state.folder.currentFolderId);


  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);
  const [folderName, setFolderName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [files, setfiles] = useState([])
  const [currentPage, setcurrentPage] = useState(1)
  const [currentSet, setCurrentSet] = useState(1);
  const [searchKeyword, setsearchKeyword] = useState("")
 const [FolderDetails, setFolderDetails] = useState([])
  const [TotalPage, setTotalPage] = useState("")
  const [errors, setErrors] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch();



  // const getFiles = async () => {
  //   const data = {
  //     search: searchKeyword,
  //     clientId: id,
  //     page: currentPage,
     
  //     limit: 10
  //   }
  //   try {
  //     const files = await FileManagerService.getFile(data)
  //     setcurrentPage(files.currentPage)
  //     setfolderParent(files.rootFolders) 
  //     setTotalPage(files.totalPages)
  //     setfiles(files.files)



  //   } catch (err) {
  //     setfiles([])
  //     console.log(err)
  //   }
  // }


  const handlesearchFile = (e) => {
    setsearchKeyword(e.target.value)
  }


  const handleFolderNameChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileId)) {

        return prevSelectedFiles.filter((id) => id !== fileId);
      } else {

        return [...prevSelectedFiles, fileId];
      }
    });
  };

  const getFolderDetails=async()=>{
    const data = {
      search: searchKeyword,
      
      folderId: currentFolderId,
      page: currentPage,
      limit: 25
    }
    try {
      const FolderDetails = await FileManagerService.getFolderDetails(data)
      
    
      // setFolderDetails(FolderDetails.files.data)
      setfolderParent(FolderDetails.subfolders.data)
    } catch (err) {
    
      console.log(err)
    }
  }


  const handleSaveFile = async () => {

    const validationErrors = validateFolderCreation(folderName, selectedFiles);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {

      client_id: id,
      file_ids: selectedFiles,
      name: folderName,
      parent:currentFolderId,
      created_by: "admin",

    }
    try {

      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));
    await FolderService.AddFileToFolder(data)
      // getFolder()
      if(currentFolderId){

        getFiles(currentFolderId)
      }else{
        getFiles()
      }
      handleCloseFolderUplaod()
      dispatch(setMessage(message.folderCreated));

    } catch (err) {

      console.log(err)
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  }
  const handleclose = () => {
    handleCloseFolderUplaod()
  }


  // useEffect(() => {
  //   getFiles()
  // }, [currentPage, searchKeyword])
  return (
    <div className="createfolder-container">
      <div className="main-search">
        <label className="form-label">Folder Name</label>
        <input
          type="text"
          className="form-control form-control-md"
          placeholder="Folder Name"
          aria-label="Folder Name"
          aria-describedby="addon-wrapping"
          value={folderName}
          onChange={handleFolderNameChange}
        />

        {errors.folderName && <Error error={errors.folderName} />}
      </div>
      {/* <div className="card">
        <div className="card-body">
          <div className="header">
            <p>Select File(s) to add to folder</p>
            <div className="header-input">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search..."
                aria-label="search"
                aria-describedby="addon-wrapping"
                onChange={handlesearchFile}
              />
            </div>
          </div>
          <table className="list-table table table-hover table-sm mb-2">
            <thead>
              <tr>

                <th width="70%">File(s)</th>
                <th width="30%" className="upload">Uploaded on</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>




                  <td>
                    <div className="d-flex align-items-center">
                      <input
                        className="form-check-input me-2 border-secondary"
                        type="checkbox"
                        value={file.id}
                        checked={selectedFiles.includes(file.id)}
                        onChange={() => handleCheckboxChange(file.id)}
                      />

                      <div className="file-name d-flex gap-2 align-items-center">
                        <div role="button"><img style={{ width: "16px" }} src={getFileIcon(file.file_name)} alt={`${file.file_name} Icon`} className="file-icon" /></div>
                        <span>{file.file_name}</span>
                      </div>
                    </div>
                  </td>
                  <td className="upload">{file.uploaded_on}</td>
                </tr>
              ))}
            </tbody>


          </table>
          <div className="float-end mt-2">
            <Pagination
              currentPage={currentPage}
              TotalPage={TotalPage}
              currentSet={currentSet}
              setCurrentSet={setCurrentSet}
              setcurrentPage={setcurrentPage}
            />
          </div>
          {errors.selectedFiles && <Error error={errors.selectedFiles} />}
        </div>
      </div> */}
      <div className="action-buttons justify-content-between">
      <Button variant="outline-secondary" className="cancel-btn" onClick={handleclose}>
          Cancel
        </Button>

        <Button variant="primary" className="save-btn" onClick={handleSaveFile} disabled={isLoading}>
          {/* Save */}
          {isLoading ? (loadingmessage) : ("Save Folder")}
        </Button>
        
      </div>
    </div>
  );
};

export default FilemanagerCreateFolder;
